nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #1b2430;
    padding: 20px 80px;
    box-shadow: 0 5px 15px rgb(0, O, 0 ,O.06);
    position: fixed;
    width: 100%;
    overflow-y: auto;
    z-index: 1000;
    margin-bottom: 20px;
    
}

#navBarUser{
    display: flex;
    align-items: center;
    justify-content: center;
}

#navBarUser li{
    list-style: none;
    padding: O 20px 0 20px;
    position: inline-block;
    margin: 0 20px;
    cursor: pointer;
}

#navBarUser li a{
    text-decoration: none;
    font-size: 1rem;
    font-weight:400;
    color: #ffff;
    transition: 0.3s ease-in-out;

}

#profile-link{
    margin-left: 10%;
    margin-right: 10%;
}


#navBarUser li a:hover,
#navBarUser li a.active{
    color: #17cf97;
}

#navBarUser li a :hover::after,
#navBarUser li a.active::after{
    content: "";
    width: 30%;
    height: 2px;
    background: #17cf97;
    position: absolute;
    bottom: -4px;
    left: 5px;
}






.labell-title-facture{
  font-weight: bold;
  font-size: 18px;
}

.content-cra{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.date-picker{
  width:100%;
}

.select-client{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

}

.select2-container--default .select2-selection--single .select2-selection__arrow{
  position: relative;
  top:-7px
}

.cra-table{
  margin-top: 2rem;
}
.btn-action-save{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 12rem;
}

.inline-center{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

a:link{
  font-size: 15px;
  text-decoration:none;
}

a:active{
  color: #0ba1b5;
}

a:hover {
  color: #46b6c3 !important;
}

.horizontal-menu .bottom-navbar .page-navigation > .nav-item a.active{
  color: #46b6c3;
  margin: 0 !important;
  font-size: 15px; font-weight: bold;

}

.menu-title{
  margin-left: .5rem;
}
.nav-item{
  padding:  1rem 0 1rem 0;
}

.material-icons {
  color: #48aae2;
}

i{
  color: #48aae2;
}

.buttonload {
  background-color: #04AA6D; /* Green background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 24px; /* Some padding */
  font-size: 18px; /* Set a font-size */
}

/* Add a right margin to each icon */
.fa {
  margin-left: 10px;
  margin-right: 10px;
  color: white!important;
  font-size: 1.2rem!important;
}

.new-facture{
  padding: .5rem 2rem;
  color: white;
  background-color: #0d87e4;
}

.new-facture:active{
  color: white!important;
  background-color: #0b5ba5!important;
}

.new-facture:hover{
  padding: .5rem 2rem;
  color: white;
  background-color: #0b7bcb;
}

nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #1b2430;
   /* padding: 20px 80px;*/
   padding-left: 120px;
   padding-right: 80px;
   /* box-shadow: 0 5px 15px rgb(0, O, 0 ,O.06);*/
    position: fixed;
    width: 100%;
   /* overflow-y: auto;*/
    z-index: 1000;
    margin-bottom: 20px;
    
}

#navBar{
    display: flex;
    align-items: center;
    justify-content: center;
}

#navBar li{
    list-style: none;
  /*  padding: O 20px 0 20px;*/
    position: inline-block;
    margin: 0 30px 0 30px;
    cursor: pointer;
}

#navBar li a{
    text-decoration: none;
    font-size: 1rem;
    font-weight:400;
    color: #ffff;
    transition: 0.3s ease-in-out;

}

#profile-link{
    margin-left: 10%;
    margin-right: 10%;
}


#navBar li a:hover,
#navBar li a.active{
    color: #17cf97;
}

#navBar li a :hover::after,
#navBar li a.active::after{
    content: "";
    width: 30%;
   /* height: 2px*/
    background: #17cf97;
    position: absolute;
    bottom: -4px;
    left: 5px;
}

#mobile{
    display: none;
}

#mobile{
    color: #ffff;
    cursor: pointer;
}

@media screen and (max-width:769px) {
    #navBar{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        top:80px;
        right: -350px;
        width: 350px;
        height: 100vh;
        background: #2a3239;
        box-shadow: 0 40px 60px rgb(0,0, 0, 0.06);
        padding: 40px 0 0 20px;
        
    }

    #navBar.active{
        right: 0px;
    }

    #mobile{
        display: block;
    }

    #navBar li{
        margin-bottom: 25px !important;

        cursor: pointer;
    }

    #mobile i{
        font-size: 24px;
        cursor: pointer;

    }

    #profile-link{
       display: none;
    }
    
}
.image-container {
    width: 100px; 
    height: 100px; 
    overflow: hidden;
    border-radius: 50%; 
    margin-bottom: 15px;
  }

  .container-button{
    background-color: #1b2430;
    color: #000;
  }
  
  .container-button:hover {
    background-color: #FFF;
    color: #1b2430;
  }